.homepage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin-top: -140px;
  text-align: center;
  background-color: black;
  padding-top: 140px;
}

@media (max-width: 1400px) {
}
@media (max-width: 1200px) {
}
@media (max-width: 992px) {
}
@media (max-width: 768px) {
}
@media (max-width: 576px) {
}
@media (max-width: 420px) {
}
