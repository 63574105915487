/*  */
.Books {
  background-color: var(---basedark);
}
.bookpage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 20px;
}

.bookpage h2 {
  font-size: 46px;
  font-family: var(---fontheadline);
  color: var(---baselight);
}
/* display books */
.bookpage .displaybackgoundCTFY {
  background: linear-gradient(
      172deg,
      rgba(0, 0, 0, 0.7) 0%,
      rgb(0, 0, 0, 0.7) 100%
    ),
    url('../media/book8ctfy.png');
}
.bookpage .displaybackgoundIDOAT {
  background: linear-gradient(
      172deg,
      rgba(0, 0, 0, 0.7) 0%,
      rgb(0, 0, 0, 0.7) 100%
    ),
    url('../media/book9idoat.png');
}
.bookpage .displaybackground {
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;

  margin: 0 auto;
  text-align: center;
}

.bookpage .displaybookimage {
  width: 160px;
}
.bookpage .displaybooktext {
  font-size: 26px;
  text-align: left;
}
.bookpage .stars {
  color: var(---baselight);
  text-align: center;
}
.bookpage .newbooklinks {
  font-size: 24px;
}
/* old books */

.bookpage h3 {
  font-family: var(---fontheadline);
  color: var(---baselight);
  font-size: 32px;
}
.bookpage .oldbackgound7 {
  background: linear-gradient(
      172deg,
      rgba(0, 0, 0, 0.7) 0%,
      rgb(0, 0, 0, 0.7) 100%
    ),
    url('../media/book7wahos.png');
}
.bookpage .oldbackgound6 {
  background: linear-gradient(
      172deg,
      rgba(0, 0, 0, 0.7) 0%,
      rgb(0, 0, 0, 0.7) 100%
    ),
    url('../media/book6tlwt.png');
}
.bookpage .oldbackgound5 {
  background: linear-gradient(
      172deg,
      rgba(0, 0, 0, 0.7) 0%,
      rgb(0, 0, 0, 0.7) 100%
    ),
    url('../media/book5imam.png');
}
.bookpage .oldbackgound4 {
  background: linear-gradient(
      172deg,
      rgba(0, 0, 0, 0.7) 0%,
      rgb(0, 0, 0, 0.7) 100%
    ),
    url('../media/book4ila.png');
}
.bookpage .oldbackgound3 {
  background: linear-gradient(
      172deg,
      rgba(0, 0, 0, 0.7) 0%,
      rgb(0, 0, 0, 0.7) 100%
    ),
    url('../media/book3tde.png');
}
.bookpage .oldbackgound2 {
  background: linear-gradient(
      172deg,
      rgba(0, 0, 0, 0.7) 0%,
      rgb(0, 0, 0, 0.7) 100%
    ),
    url('../media/book2tkt.png');
}
.bookpage .oldbackgound1 {
  background: linear-gradient(
      172deg,
      rgba(0, 0, 0, 0.7) 0%,
      rgb(0, 0, 0, 0.7) 100%
    ),
    url('../media/book1bs.png');
}
.bookpage .oldbackgound0 {
  background: linear-gradient(
      172deg,
      rgba(0, 0, 0, 0.7) 0%,
      rgb(0, 0, 0, 0.7) 100%
    ),
    url('../media/book0mhw.png');
}

.bookpage .oldbackground {
  background-repeat: no-repeat;
  background-size: cover;
  height: 95%;
  margin: 0 auto;
  text-align: center;
}
.bookpage .oldbookimage {
  width: 33%;
}
.bookpage .oldbooktext {
  font-size: 22px;
  text-align: left;
}
.bookpage .oldbooklinks {
  font-size: 18px;
}

@media (max-width: 1400px) {
  .bookpage .displaybookimage {
    width: 140px;
  }

  .bookpage h3 {
    font-size: 35px;
  }

  .bookpage .oldbooktext {
    font-size: 23px;
  }
  .bookpage .oldbooklinks {
    font-size: 20px;
  }
}
@media (max-width: 1200px) {
  .bookpage .displaybookimage {
    padding-top: 10px;
  }
  .bookpage .displaybooktext {
    font-size: 24px;
  }
  .bookpage .newbooklinks {
    font-size: 20px;
  }
  .bookpage h3 {
    font-size: 35px;
  }
  .bookpage .oldbookimage {
    padding-top: 5px;
  }
  .bookpage .oldbooktext {
    font-size: 21px;
  }
  .bookpage .oldbooklinks {
    font-size: 18px;
  }
}
@media (max-width: 992px) {
  .bookpage .displaybookimage {
    width: 34%;
    padding-top: 0px;
  }

  .bookpage h2 {
    font-size: 50px;
  }

  .bookpage .oldbooklinks {
    font-size: 19px;
  }
}
@media (max-width: 768px) {
  .bookpage .displaybookimage {
    width: 31%;
  }
  .bookpage .oldbackground {
    height: 90%;
  }
  .bookpage .oldbookimage {
    padding-top: 5px;
    width: 23%;
  }
  .bookpage .oldbooktext {
    font-size: 22px;
  }
  .bookpage .oldbooklinks {
    font-size: 20px;
  }
}
@media (max-width: 576px) {
  .bookpage .displaybookimage {
    width: 35%;
  }

  .bookpage h2 {
    font-size: 40px;
  }

  .bookpage .displaybooktext {
    font-size: 22px;
    text-align: left;
  }

  .bookpage .oldbookimage {
    width: 28%;
  }
  .bookpage .oldbooktext {
    font-size: 20px;
  }
}
@media (max-width: 420px) {
  .bookpage .displaybookimage {
    width: 30%;
  }

  .bookpage h2 {
    font-size: 35px;
  }

  .bookpage .displaybooktext {
    font-size: 18px;
  }
  .bookpage .newbooklinks {
    font-size: 17px;
  }
  .bookpage h3 {
    font-size: 24px;
  }

  .bookpage .oldbooktext,
  .bookpage .oldbooklinks {
    font-size: 16px;
  }
}
