/* checked */
.Contact {
  background-color: var(---basedark);
}
.contactpage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 20px;
  text-align: center;
}

.contactpage h1 {
  margin: 10px 5px 40px 5px;

}
.contactpage .contacttext {
  font-size: 22px;
}
.contactpage .contactimage {
  max-height: 400px;
}
.iconContainer{
width: 70%;
margin: 0 auto;
}
.contactpage .contactlinkicon {
  color: var(---baselight);
  font-size: 26px;
}

.contactpage .contactlinkicon:hover {
  color: var(---base);
}
.contactpage .contactlink {
  margin: 15px auto 30px;
}

.contactpage .contactlink:hover {
  color: var(---base);
}

@media (max-width: 992px) {
  .contactpage h1 {
    font-size: 60px;
  }
  .contactpage .contacttext {
    font-size: 24px;
  }
  .contactpage .contactimage {
    max-height: 450px;
  }
}
@media (max-width: 768px) {
}
@media (max-width: 576px) {
    .contactpage .contacttext {
    font-size: 22px;
  }
  .contactpage .contactimage {
    max-height: 400px;
  }
}
@media (max-width: 420px) {
     .contactpage .contacttext {
    font-size: 20px;
  }
  .contactpage .contactimage {
    max-height: 350px;
  }
}
