
.Events {
  background-color: var(---basedark);
}
.eventspage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 20px;
  font-size: 22px;
}
.eventspage .event1 {
  padding: 40px 80px;
}
.eventspage .joinbutton {
  font-size: 20px;
}

@media (max-width: 576px) {
  .eventspage {
    font-size: 20px;
  }
  .eventspage h1 {
    font-size: 60px;
  }
  .eventspage .event1 {
    padding: 30px 30px;
  }
  .eventspage .joinbutton {
    font-size: 18px;
  }
}
