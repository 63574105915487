
.currentNews .book1 {
  max-height: 45vh;
}

.currentNews .newText {
  font-size: 22px;
}
.currentNews .republish {
  display: flex;
  gap: 20px;
  flex-direction: row;
  align-items: center;
}
.currentNews .kindle {
  color: black;
  background-color: var(---baselight);
  border-radius: 8px;
}

@media (max-width: 992px) {
  .currentNews .newText {
    font-size: 18px;
  }
}
@media (max-width: 769px) {
  .currentNews .newText {
    font-size: 16px;
  }
}
@media (max-width: 576px) {
  .currentNews .republish {
    gap:10px;
    flex-direction: column;
  }
  .currentNews .newText {
  width: 90%;
}
}
