.linkInBio {
  /* background-color: red; */
  background: linear-gradient(
      172deg,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(0, 0, 0, 0.8) 100%
    ),
    url('../media/redsky.jpg');
  background-position: top left;
  background-repeat: no-repeat;
  background-size: cover;
}

.linkInBio .linkInBioPage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 20px 10%;
  font-size: 22px;
}
.linkInBio img {
  height: 150px;
  width: 150px;
  border-radius: 50%;
}
.linkInBio .link {
  border: 2px solid var(---baselight);
}
@media (max-width: 775px) {
  .linkInBio img {
    height: 130px;
    width: 130px;
  }
  .linkInBio h1 {
    font-size: 60px;
  }
}
@media (max-width: 420px) {
  .linkInBio img {
    height: 100px;
    width: 100px;
  }
  .linkInBio h1 {
    font-size: 50px;
  }
  .linkInBio .linkInBioPage {
    padding: 20px 2%;
  }
  .linkInBio .link {
    font-size: 18px;
  }
}
