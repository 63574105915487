/* checked */
.abouttheauthor h3 {
  font-size: 70px;
  font-family: var(---fontheadline);
  color: var(---baselight);
}
.abouttheauthor .aboutJaneCorry {
  background-color: var(---dark);
  /* background: linear-gradient(
      172deg,
      rgba(1, 20, 21, 0.7) 0%,
      rgb(1, 20, 21, 0.7) 100%
    ),
    url("../../media/jccoverwindow.png"); */
}
.abouttheauthor .Newsletterprompt {
  font-size: 17px;
}
.abouttheauthor .jcimagesmall {
  max-height: 450px;
  /* filter: saturate(130%) grayscale(90%); */
}
.abouttheauthor .jcdescription {
  font-size: 22px;
  line-height: 33px;
}
@media (max-width: 992px) {
  .abouttheauthor h3 {
    font-size: 65px;
  }
  .abouttheauthor .jcdescription {
    font-size: 19px;
    line-height: 30px;
  }  
}

@media (max-width: 768px) {
  .abouttheauthor h3 {
    font-size: 60px;
  }
  .abouttheauthor .jcdescription {
    line-height: 29px;
  }
  .abouttheauthor .jcimagesmall {
    max-height: 300px;
  }
  .abouttheauthor .Newsletterprompt {
    font-size: 16px;
  }
}
@media (max-width: 576px) {
  .abouttheauthor h3 {
    font-size: 50px;
  }
  .abouttheauthor .jcdescription {
    font-size: 17px;
    line-height: 25px;
  }
  .abouttheauthor .jcimagesmall {
    max-height: 280px;
  }
  .abouttheauthor .Newsletterprompt {
    font-size: 15px;
  }
}

@media (max-width: 420px) {
  .abouttheauthor h3 {
    font-size: 43px;
  }
  .abouttheauthor .jcimagesmall {
    max-height: 250px;
  }
  .abouttheauthor .jcdescription {
    font-size: 15px;
    line-height: 25px;
  }
  .abouttheauthor .Newsletterprompt {
    font-size: 14px;
  }
}
