/* checked */
.Aboutme {
  background-color: var(---basedark);
}
.aboutmepage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 20px 120px;
}
.aboutmepage h2 {
  font-size: 60px;
  font-family: var(---fontheadline);
  color: var(---baselight);
}
.aboutmepage .profile {
  max-width: 40%;
}
.aboutmepage .aboutmetext,
.aboutmepage .quote {
  font-size: 24px;
}
.aboutmepage .bio {
  font-size: 22px;
}

@media (max-width: 1200px) {
  .aboutmepage .aboutmetext,
  .aboutmepage .quote {
    font-size: 22px;
  }
  .aboutmepage .bio {
    font-size: 20px;
  }
}
@media (max-width: 992px) {
  .aboutmepage {
    padding: 20px 80px;
  }
  .aboutmepage .profile {
    max-width: 300px;
  }
}
@media (max-width: 768px) {
    .aboutmepage {
    padding: 20px 60px;
  }
  .aboutmepage .profile {
    max-width: 250px;
    margin: 20px auto 0px;
  }
}
@media (max-width: 576px) {
     .aboutmepage {
    padding: 20px 20px;
  }
  .aboutmepage .profile {
    max-width: 300px;
  }
  .aboutmepage .aboutmetext,
  .aboutmepage .quote {
    font-size: 22px;
  }
  .aboutmepage .bio {
    font-size: 18px;
  }
}
@media (max-width: 420px) {
    .aboutmepage {
    padding: 20px 0px;
  }
  .aboutmepage .profile {
    max-width: 200px;
    margin: 20px auto 0px;
  }
  .aboutmepage h1 {
    font-size: 55px;
  }
  .aboutmepage h2 {
    font-size: 45px;
  }
  .aboutmepage .aboutmetext,
  .aboutmepage .quote {
    font-size: 18px;
  }
  .aboutmepage .bio {
    font-size: 16px;
  }
}
