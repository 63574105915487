.nextBook {
  padding: 20px 15% 50px;
  background: linear-gradient(
      172deg,
      rgba(0, 0, 0, 0.673) 0%,
      rgba(0, 0, 0, 0.714) 100%
    ),
    url('../../media/redsky.jpg');
  background-position: top left;
  background-repeat: no-repeat;
  background-size: cover;
}
.nextBook h1,
.nextBook h3 {
  color: #0ff0af;
}
.nextBook p {
  font-size: 26px;
  line-height: 35px;
}
.nextBook .starreviewsection p {
  font-size: 20px;
}
.nextBook .starreviewsection .author {
font-style: italic;
}
.nextBook a {
  border: 1px solid #0ff0af;
  border-radius: 4px;
  background-color: #0ff0af;
  color: var(---dark);
  font-size: 18;
  text-decoration: none;
}

.nextBook img {
  max-height: 300px;
}
.nextBook a:hover {
  background-color: #211516;
  color: #0ff0af;
}

@media (max-width: 1200px) {
  .nextBook p {
    font-size: 22px;
    line-height: 30px;
  }
}

@media (max-width: 992px) {
  .nextBook {
    padding: 20px 10% 50px;
  }
  .nextBook h1 {
    font-size: 65px;
  }
  .nextBook p {
    font-size: 19px;
  }
  .nextBook a {
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  .nextBook {
    padding: 20px 3% 50px 5%;
  }
  .nextBook h1 {
    font-size: 60px;
  }
  .nextBook p {
    line-height: 29px;
  }
}
@media (max-width: 576px) {
  .nextBook h1 {
    font-size: 50px;
  }
  .nextBook p {
    font-size: 20px;
    line-height: 25px;
  }
}
@media (max-width: 420px) {
  .nextBook {
    padding: 20px 5% 50px;
  }
  .nextBook h1 {
    font-size: 43px;
  }
  .nextBook p {
    font-size: 19px;
  }
  .nextBook a {
    font-size: 15.9px;
  }
}
