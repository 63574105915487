:root {
  --media-primary-color: rgb(255, 190, 0);
}

.News {
  background-color: var(---basedark);
}

.newspage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 20px 10%;
  font-size: 22px;
}

.newspage hr {
  border: 4px var(---baselight);
  border-style: double;
  color: var(---dark);
  opacity: 0.7;
}
.newspage .news,
.newspage .news,
.newspage .news {
  padding: 30px 50px;
}

.newspage .middleimage {
  max-height: 650px;
  height: auto;
}
.newspage  .canadaenlarge {
  font-size: 12px !important;
}
.canadaimagecontainer {
  align-items: center;
}
.canadaImage {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}
.canadaImage:hover {
  transform: scale(1.5);
}
/* social media */
.newspage .iconContainer {
  width: 70%;
  margin: 0 auto;
}
.newspage .contactlinkicon {
  color: var(---baselight);
  font-size: 26px;
}

.newspage .contactlinkicon:hover {
  color: var(---base);
}

.MediaContoller:host,
.MediaContoller {
  background-color: var(---baselight);
}

.embed {
  max-width: 330px;
}
@media (max-width: 992px) {
  .newspage {
    padding: 20px 5%;
    font-size: 20px;
  }
  .newspage .newsOne,
  .newspage .newsTwo,
  .newspage .newsThree {
    padding: 20px 30px;
  }
}
@media (max-width: 768px) {
  .newspage .newsOne,
  .newspage .newsTwo,
  .newspage .newsThree {
    padding: 15px 15px;
  }
  .newspage .wahoscover {
    max-width: 50%;
  }
}
@media (max-width: 576px) {
  .newspage .wahoscover {
    max-width: 60%;
  }
}
@media (max-width: 420px) {
  .newspage {
    padding: 20px 0;
    font-size: 18px;
  }
  .newspage .wahoscover {
    max-width: 80%;
  }
}

@media (max-width: 351px) {
  .insta {
    display: none;
  }
}
