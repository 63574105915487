.newssection h2 {
  font-size: 60px;
  font-family: var(---fontheadline);
  color: var(---baselight);
}
.newssection .newsCardContainer {
  padding: 30px 60px;
}

.newssection .cards {
  width: 95%;
  height: auto;
  min-height: 98%;
  overflow: auto;
  margin: 0 auto;
  border: 3px double var(---baselight);
}
.newssection .cardtext {
  font-size: 20px;
}
.domesticatext {
  font-size: 16px;
}
.canadalist {
  font-size: 16px;
}
.newssection .cardImage {
  max-width: 40%;
  height: auto;
}
.newssection .domesticaImage {
  height: auto;
}
.newssection .canadaImage {
  height: auto;
}
.newssection  .canadaenlarge {
  font-size: 12px !important;
}
@media (max-width: 992px) {
  .newssection .cardImage {
    max-height: 240px;
  }
  .newssection .cards {
    width: 65%;
  }
  .newssection .domesticaImage {
    max-width: 80%;
  }
  .newssection .canadaImage {
    max-width: 80%;
  }
  .domesticatext {
    font-size: 20px;
  }
  .canadalist {
    font-size: 20px;
  }
}
.canadaimagecontainer {
  align-items: center;
}
.canadaImage {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}
.canadaImage:hover {
  transform: scale(1.5);
}

@media (max-width: 769px) {
  .newssection .newsCardContainer {
    padding: 25px 20px;
  }
  .newssection .cards {
    width: 78%;
  }
  .newssection .cardImage {
    max-height: 220px;
  }
}
@media (max-width: 576px) {
  .newssection h2 {
    font-size: 50px;
  }
  .newssection .newsCardContainer {
    padding: 20px 0px;
  }
  .newssection .cards {
    width: 85%;
    padding: 10px 0 5px 0;
  }

  .newssection .cardtext {
    font-size: 19px;
    padding: 20px 20px 5px 20px;
  }
  .domesticatext {
    font-size: 19px;
    padding: 20px 20px 5px 20px;
  }
  .canadalist {
    font-size: 19px;
    padding: 20px 20px 5px 20px;
  }
  .newssection .cardImage {
    max-height: 180px;
  }
}

@media (max-width: 420px) {
  .newssection .cardtext {
    font-size: 18px;
  }
  .domesticatext {
    font-size: 18px;
  }
  .canadalist {
    font-size: 18px;
  }
  .newssection .cards {
    max-width: 95%;
  }
  .newssection .cardImage {
    max-height: 140px;
  }
}
