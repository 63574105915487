
.slideshow .book {
  max-height: 240px;
}
/* .slideshow hr {
  border: 4px var(---baselight);
  border-style: double;
  color: var(---dark);
  opacity: 0.7;
} */
@media (max-width: 576px) {
  .slideshow .book {
    max-height: 200px;
  }
}
@media (max-width: 420px) {
  .slideshow .book {
    max-height: 160px;
  }
}
