:root {
  ---base: rgb(255, 255, 255);
  ---dark: #001112;
  ---baselight: #FFE099;
  ---basedark: #011415;
  ---fontheadline: "Alice", serif;
}

h1 {
  font-size: 70px;
  font-family: var(---fontheadline);
  color: var(---baselight);
}
.btnLightDark {
  border: 1px solid var(---baselight);
  border-radius: 4px;
  background-color: var(---baselight);
  color: var(---dark);
  text-decoration: none;
}
.btnLightDark:hover {
  background-color: var(---dark);
  color: var(---baselight);
}

.btnDarkLight {
  border: 1px solid var(---baselight);
  border-radius: 4px;
  background-color: var(---basedark);
  color: var(---baselight);
  text-decoration: none;
}
.btnDarkLight:hover {
  background-color: var(---baselight);
  color: var(---dark);
}
.btnNoneLight {
  border: 1px solid var(---baselight);
  border-radius: 4px;
  background-color: none;
  color: var(---baselight);
  text-decoration: none;
}
.btnNoneLight:hover {
  background-color: var(---baselight);
  color: var(---dark);
}
.linkLightBase {
  color: var(---baselight);
  text-decoration: none;
}
.linkLightBase:hover {
  color: var(---base);
}
.highlight {
  color: var(---baselight);
}
.buttonLink {
  background: none;
  border: none;
  color: var(---baselight);
}
.buttonLink:hover {
  color: var(---base);
}
/*  */

.App {
  color: var(---base);
  font-family: "Catamaran", sans-serif;
}
