.Error {
  background-color: var(---basedark);
}

.errorpage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 20px 10%;
  font-size: 22px;
}
.errorpage h1 {
  font-size: 30px;
  color: var(---base);
}

@media (max-width: 1400px) {
}
@media (max-width: 1200px) {
}
@media (max-width: 992px) {
}
@media (max-width: 768px) {
}
@media (max-width: 576px) {
}
@media (max-width: 420px) {
}
