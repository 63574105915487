.canadabanner {
  background-color: #0ff0af;
  color: black;
}
.canadabanner h3 {
  font-size: 45px;
  font-family: var(---fontheadline);
}
.canadabanner a {
  color: black;
}
.canadabanner p {
  font-size: 24px;
}

.canadabanner a:hover {
  color: white;
}

@media (max-width: 992px) {
  .canadabanner h3 {
    font-size: 40px;
  }
  .canadabanner p {
    font-size: 20px;
  }
}
@media (max-width: 769px) {
  .canadabanner h3 {
    font-size: 30px;
  }
  .canadabanner p {
    font-size: 18px;
  }
}
