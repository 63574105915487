/* checked */
.Navigation nav {
  position: sticky;
  top: 0;
  height: fit-content;
  padding: 7px 0;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.7);
  background-color: var(---basedark);
  z-index: 100;
}
.navbar .nav-link {
  font-size: 20px;
  font-family: var(---fontheadline);
  color: var(---baselight);
}
.navbar .nav-link.active,
.navbar .nav-link:active {
  color: var(---base);
}

.navbar .smalllogo {
  height: 30px;
  filter: saturate(60%) hue-rotate(-10deg);
}
.navbar .navbar-toggler-icon {
  padding-top: 5px;
  border: 1px solid var(---baselight);
  border-radius: 5px;
  font-size: 30px;
  color: var(---baselight);
}
.navbar .dropdown-menu {
  background-color: var(---basedark);
  color: var(---baselight);
}
.navbar .dropdown-item {
  color: var(---base);
}
.navbar .dropdown-item:hover {
  background-color: var(---baselight);
  color: var(---basedark);
}
.navbar .dropdown-item.active,
.navbar .dropdown-item:active {
  background-color: var(---baselight);
  color: var(---basedark);
}

@media (max-width: 768px) {
  .navbar .nav-link {
    font-size: 18px;
  }
}
@media (max-width: 576px) {
  .navbar .nav-link {
    font-size: 16px;
  }
  .navbar .smalllogo {
    height: 30px;
  }
  .navbar .navbar-toggler-icon {
    font-size: 22px;
  }
}
@media (max-width: 420px) {
  .navbar .nav-link {
    font-size: 14px;
  }
  .navbar .smalllogo {
    height: 26px;
  }
  .navbar .navbar-toggler-icon {
    padding: 3px 0 0 1px;
    font-size: 18px;
  }
  .navbar .dropdown-item {
    font-size: 14px;
  }
}
