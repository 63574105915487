.tsirsrelease {
  background-color: #65c6e9;
  color: black;
}
.tsirsrow {
  padding: 4px 15% 10px;
}
.tsirsrelease h3 {
  font-size: 45px;
  font-family: var(---fontheadline);
}
.tsirsrelease a {
  border: 1px solid #faff4d;
  border-radius: 4px;
  background-color: #faff4d;
  padding: 5px;
  color: black;
  font-size: 18px;
  text-decoration: none;
}
.tsirsrelease a:hover {
    background-color: #65c6e9;
  color: #faff4d;
}

.tsirsrelease p {
  font-size: 24px;
}

.tsirshighlight {font-family: var(---fontheadline);
  color: #faff4d;
  font-weight: 600;
}

@media (max-width: 992px) {
  .tsirsrelease h3 {
    font-size: 40px;
  }
  .tsirsrelease p {
    font-size: 20px;
  }
}
@media (max-width: 769px) {
  .tsirsrelease h3 {
    font-size: 30px;
  }
  .tsirsrelease p {
    font-size: 18px;
  }
  .tsirsrow {
  padding: 4px 5% 10px;
}
}
