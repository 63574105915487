/* checked */
.Newsletter {
  background-color: var(---basedark);
}
.newsletterpage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 20px 80px;
  text-align: center;
}
.newsletterpage .newsletterimage {
  max-width: 80%;
}
.newsletterpage .newslettertext {
  font-size: 28px;
}
.newsletterpage .newsletterbutton {
  font-size: 22px;
}

@media (max-width: 992px) {
  .newsletterpage .newsletterimage {
    max-width: 80%;
  }
  .newsletterpage .newslettertext,
  .newsletterpage .newsletterbutton {
    font-size: 34px;
  }
}

@media (max-width: 576px) {
  .newsletterpage h1 {
    font-size: 60px;
  }

  .newsletterpage .newslettertext,
  .newsletterpage .newsletterbutton {
    font-size: 26px;
  }
}
@media (max-width: 420px) {
  .newsletterpage h1 {
    font-size: 55px;
  }

  .newsletterpage .newslettertext,
  .newsletterpage .newsletterbutton {
    font-size: 19px;
  }
}
